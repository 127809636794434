import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#fa4d3e',
      main: '#EC3323',
      dark: '#d12819',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FFF',
    },
    type: 'dark',
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        height: '42px',
        paddingLeft: '35px',
        paddingRight: '35px',
        borderRadius: '5px',
      },
    },
    MuiButtonGroup: {
      root: {
        overflow: 'hidden !important',
        borderRadius: '5px !important'
      },
    },
    MuiInputBase: {
      root: {
        padding: '0px !important',
      },
      input: {
        fontWeight: '500',
        padding: '12px !important'
      }
    },
    MuiFilledInput: {
      root: {
        fontSize: '0.9375rem',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        borderRadius: '5px',
        overflow: 'hidden',
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingRight: '40px !important'
      }
    },
    MuiInputAdornment: {
      root: {
        marginLeft: '12px',
      }
    }
  },
});

export default theme;
