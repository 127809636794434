import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import FourOhFour from '../pages/FourOhFour';

const Index = loadable(() => import('../pages/Index'));
const Test = loadable(() => import('../pages/IFrame'));
const ParcelOptions = loadable(() => import('../pages/ParcelOptions'));
const Products = loadable(() => import('../components/Products'));
const BillPay = loadable(() => import('../pages/BillPay'));

export const ROUTES = [
  { url: '/test', name: 'Test', component: Test },
  { url: '/parcel-options', name: 'Parcel Options', component: ParcelOptions },
  { url: '/products', component: Products },
  { url: '/bill-pay', name: 'Bill Pay', component: BillPay },
  { url: '/external', component: loadable(() => import('../pages/External')) },
];
export default class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={ParcelOptions} />
          {ROUTES.map(r => (
            <Route key={r.url} path={r.url} exact component={r.component} />
          ))}
          <Route component={FourOhFour} />
        </Switch>
      </BrowserRouter>
    );
  }
}
