import React, { Component, Fragment } from 'react';
export default class Page extends Component {
  render() {
    return (
      <Fragment>
        <title>404 page not found</title>

        <div id="features">
          <div className="container">
            <div className="row header">
              <div className="col-md-12" align="left">
                <h1>No Page here</h1>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
