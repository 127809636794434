import 'core-js/stable';
import 'regenerator-runtime/runtime';
// import 'core-js';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
WebFont.load({
  google: {
    families: ['Titillium Web:400,700', 'sans-serif'],
  },
});

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}
