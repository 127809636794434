import React, { Component } from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider as MaterialUIThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Router from './components/Router';
import lightTheme from './muiTheme';
import darkTheme from './darkMuiTheme';

class App extends Component {
  render() {
    const theme = window.location.pathname === '/external' ? darkTheme : lightTheme;
    return (
      <MaterialUIThemeProvider theme={theme}>
        <StyledComponentsThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CssBaseline />
            <Router />
          </MuiPickersUtilsProvider>
        </StyledComponentsThemeProvider>
      </MaterialUIThemeProvider>
    );
  }
}

export default App;
